import React from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import { motion } from 'framer-motion';

const Hero = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        backgroundImage: 'url(/mcg-background.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
      }}
    >
      <Container>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <Box
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              padding: '2rem',
              borderRadius: '8px',
            }}
          >
            <Typography
              variant="h1"
              align="center"
              sx={{ fontWeight: 700, mb: 3, fontSize: { xs: '2.5rem', md: '4rem' } }}
            >
              African Solutions for African Challenges
            </Typography>
            <Typography
              variant="h5"
              align="center"
              sx={{ mb: 4, opacity: 0.9 }}
            >
              We build custom software solutions tailored to Africa's unique needs
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              sx={{
                borderRadius: '30px',
                px: 4,
                py: 1.5,
                borderWidth: '2px',
                '&:hover': {
                  borderWidth: '2px',
                },
              }}
            >
              Explore Our Solutions
            </Button>
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
};

export default Hero;
