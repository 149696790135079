import React from 'react';
import { Box, Container, Grid, Typography, Card, CardContent } from '@mui/material';
import { CodeRounded, StorageRounded, PhoneAndroidRounded } from '@mui/icons-material';
import { motion } from 'framer-motion';

const services = [
  {
    title: 'Custom Software Development',
    description: 'Tailored solutions designed specifically for African businesses and organizations',
    icon: CodeRounded,
  },
  {
    title: 'Cloud Solutions',
    description: 'Scalable and reliable cloud infrastructure optimized for African markets',
    icon: StorageRounded,
  },
  {
    title: 'Mobile Applications',
    description: 'User-friendly mobile apps that work seamlessly in African contexts',
    icon: PhoneAndroidRounded,
  },
];

const Services = () => {
  return (
    <Box id="services" sx={{ py: 8, backgroundColor: '#f5f5f5' }}>
      <Container>
        <Typography
          variant="h3"
          component="h2"
          sx={{ textAlign: 'center', mb: 6, fontWeight: 600 }}
        >
          Our Services
        </Typography>
        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} md={4} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 2,
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  }}
                >
                  <CardContent>
                    <Box sx={{ textAlign: 'center', mb: 2 }}>
                      <service.icon sx={{ fontSize: 50, color: '#1a237e' }} />
                    </Box>
                    <Typography variant="h5" component="h3" sx={{ mb: 2, textAlign: 'center' }}>
                      {service.title}
                    </Typography>
                    <Typography color="text.secondary" sx={{ textAlign: 'center' }}>
                      {service.description}
                    </Typography>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Services;
